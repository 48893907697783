import React from "react"
import SEO from "../components/seo"
import netlifyIdentity from 'netlify-identity-widget';

//components

//material-ui
import Box from '@material-ui/core/Box'

//styles
import styles from "../styles/index.module.css"

if (typeof window !== 'undefined' && typeof netlifyIdentity.init !== `undefined`) {
  netlifyIdentity.init();
}

function IndexPage(props) {
  return (
    <Box width="auto" className={styles.box}>
      <SEO title="Home" />
      <a className={styles.mail} href="mailto:cordell.blackford@gmail.com">
        Cordell Blackford
        </a>
    </Box>
  )
}

export default IndexPage
